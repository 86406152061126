<template>
  <section>
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>
    <div class="max-w-6xl mx-auto mt-3 lg:mt-10 md:mt-5" v-if="isLoaded">
      <!-- one -->
      <div class="flex items-center justify-between">
        <div class="font-bold text-22px text-oCharcoal">
          <i class="fas fa-bicycle text-22px text-oPurple"></i>
          <span class="pl-3">{{
            $t('components.settingsManagement.vehicle.headline.vehicleSettings')
          }}</span>
        </div>
      </div>
      <div :key="`vehicle_${counter}`" class=" mt-3 pt-5 border-t-2">
        <SmartToggleInput
          :key="`vehicle_${counter + 0}`"
          :label="vehicleSettings[0].label"
          :description="vehicleSettings[0].description"
          :onText="vehicleSettings[0].onText"
          :offText="vehicleSettings[0].offText"
          :onValue="vehicleSettings[0].onValue"
          :offValue="vehicleSettings[0].offValue"
          v-model="vehicle[`${vehicleSettings[0].variable}`]"
          @save="onOff('vehicle', `${vehicleSettings[0].variable}`, $event)"
        />
        <SmartAppInput
          :key="`vehicle_${counter + 1}`"
          :rowId="`vehicle_${1}`"
          :type="vehicleSettings[1].type"
          :label="vehicleSettings[1].label"
          :description="vehicleSettings[1].description"
          :isEdit="vehicleSettings[1].isEdit"
          :options="vehicleSettings[1].options"
          v-model="vehicle[`${vehicleSettings[1].variable}`]"
          :haveUnitText="vehicleSettings[1].haveUnitText"
          :unitText="vehicleSettings[1].unitText"
          @save="onOff('vehicle', `${vehicleSettings[1].variable}`, $event)"
        />
        <SmartAppInput
          :key="`vehicle_${counter + 2}`"
          :rowId="`vehicle_${2}`"
          :type="vehicleSettings[2].type"
          :label="vehicleSettings[2].label"
          :description="vehicleSettings[2].description"
          :isEdit="vehicleSettings[2].isEdit"
          :options="vehicleSettings[2].options"
          v-model="vehicle[`${vehicleSettings[2].variable}`]"
          :haveUnitText="vehicleSettings[2].haveUnitText"
          :unitText="vehicleSettings[2].unitText"
          @save="onOff('vehicle', `${vehicleSettings[2].variable}`, $event)"
        />
        <SmartToggleInput
          :key="`vehicle_${counter + 3}`"
          :label="vehicleSettings[3].label"
          :description="vehicleSettings[3].description"
          :onText="vehicleSettings[3].onText"
          :offText="vehicleSettings[3].offText"
          :onValue="vehicleSettings[3].onValue"
          :offValue="vehicleSettings[3].offValue"
          v-model="vehicle[`${vehicleSettings[3].variable}`]"
          @save="onOff('vehicle', `${vehicleSettings[3].variable}`, $event)"
        />

        <SmartAppInput
          :key="`vehicle_${counter + 4}`"
          :rowId="`vehicle_${4}`"
          :type="vehicleSettings[4].type"
          :label="vehicleSettings[4].label"
          :description="vehicleSettings[4].description"
          :isEdit="vehicleSettings[4].isEdit"
          :options="vehicleSettings[4].options"
          v-model="vehicle[`${vehicleSettings[4].variable}`]"
          @save="onOff('vehicle', `${vehicleSettings[4].variable}`, $event)"
        />
        <SmartAppInput
          :key="`vehicle_${counter + 5}`"
          :rowId="`vehicle_${5}`"
          :type="vehicleSettings[5].type"
          :label="vehicleSettings[5].label"
          :description="vehicleSettings[5].description"
          :isEdit="vehicleSettings[5].isEdit"
          :options="vehicleSettings[5].options"
          v-model="vehicle[`${vehicleSettings[5].variable}`]"
          @save="onOff('vehicle', `${vehicleSettings[5].variable}`, $event)"
        />
        <SmartToggleInput
          :key="`vehicle_${counter + 6}`"
          :label="vehicleSettings[6].label"
          :description="vehicleSettings[6].description"
          :onText="vehicleSettings[6].onText"
          :offText="vehicleSettings[6].offText"
          :onValue="vehicleSettings[6].onValue"
          :offValue="vehicleSettings[6].offValue"
          v-model="vehicle[`${vehicleSettings[6].variable}`]"
          @save="onOff('vehicle', `${vehicleSettings[6].variable}`, $event)"
        />

        <SmartAppInput
          :key="`vehicle_${counter + 7}`"
          :rowId="`vehicle_${7}`"
          :type="vehicleSettings[7].type"
          :label="vehicleSettings[7].label"
          :description="vehicleSettings[7].description"
          :isEdit="vehicleSettings[7].isEdit"
          :options="vehicleSettings[7].options"
          v-model="basic[`${vehicleSettings[7].variable}`]"
          :haveUnitText="vehicleSettings[7].haveUnitText"
          :unitText="vehicleSettings[7].unitText"
          @save="onOff('basic', `${vehicleSettings[7].variable}`, $event)"
        />
        <SmartAppInput
          :key="`vehicle_${counter + 8}`"
          :rowId="`vehicle_${8}`"
          :type="vehicleSettings[8].type"
          :label="vehicleSettings[8].label"
          :description="vehicleSettings[8].description"
          :isEdit="vehicleSettings[8].isEdit"
          :options="vehicleSettings[8].options"
          v-model="basic[`${vehicleSettings[8].variable}`]"
          :haveUnitText="vehicleSettings[8].haveUnitText"
          :unitText="vehicleSettings[8].unitText"
          @save="onOff('basic', `${vehicleSettings[8].variable}`, $event)"
        />
        <SmartToggleInput
          :key="`vehicle_${counter + 9}`"
          :label="vehicleSettings[9].label"
          :description="vehicleSettings[9].description"
          :onText="vehicleSettings[9].onText"
          :offText="vehicleSettings[9].offText"
          :onValue="vehicleSettings[9].onValue"
          :offValue="vehicleSettings[9].offValue"
          v-model="vehicle[`${vehicleSettings[9].variable}`]"
          @save="onOff('vehicle', `${vehicleSettings[9].variable}`, $event)"
        />
      </div>

      <!-- battery -->
      <div class="flex justify-between mt-10">
        <div>
          <div class="flex items-center">
            <i class="fas fa-battery-three-quarters text-oOrange text-22px"></i>
            <div class="pl-3 font-bold text-22px text-oCharcoal">
              {{
                $t(
                  'components.settingsManagement.vehicle.headline.batteryStatus.title'
                )
              }}
            </div>
          </div>
          <p class="break-words description-text ">
            {{
              $t(
                'components.settingsManagement.vehicle.headline.batteryStatus.description'
              )
            }}
          </p>
        </div>
        <div
          class="col-span-12 pencil-icon md:col-span-3 justify-items-end justify-self-end"
        >
          <div v-if="!isBatteryEdit">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              @click="handleBatteryEdit"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="{2}"
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
              />
            </svg>
          </div>
        </div>
        <div v-if="isBatteryEdit" class="flex ml-3 mt-3 mb-4">
          <anchor-button
            @click="handleBatteryCancel"
            :text="$t('components.stepNavigation.cancel')"
            width="6rem"
          />
          <anchor-button
            @click="onOff('vehicle', 'battery_settings')"
            :text="$t('components.stepNavigation.save')"
            variant="success"
            width="6rem"
            class="ml-4"
          />
        </div>
      </div>
      <div
        class="grid grid-cols-1 mt-6 gap-x-10 gap-y-4 md:grid-cols-2 lg:grid-cols-4  mt-3 pt-5  border-t-2"
      >
        <section>
          <input-label
            :text="$t('components.settingsManagement.vehicle.battery.critical')"
          />
          <div class="flex items-center">
            <text-input class="pr-2" value="0" disabled />
            <div class="mb-6">-</div>
            <text-input
              type="number"
              v-model.number="battery.battery_critical_value"
              v-on:input="handleBatteryInputChange"
              :disabled="!isBatteryEdit"
              class="pl-2"
            />
          </div>
        </section>
        <section>
          <input-label
            :text="$t('components.settingsManagement.vehicle.battery.low')"
          />
          <div class="flex items-center">
            <text-input
              :value="battery.battery_critical_value + 1"
              class="pr-2"
              disabled
            />
            <div class="mb-6">-</div>
            <text-input
              type="number"
              v-model.number="battery.battery_low_value"
              v-on:input="handleBatteryInputChange"
              :disabled="!isBatteryEdit"
              class="pl-2"
            />
          </div>
        </section>
        <section>
          <input-label
            :text="$t('components.settingsManagement.vehicle.battery.medium')"
          />
          <div class="flex items-center">
            <text-input
              :value="battery.battery_low_value + 1"
              class="pr-2"
              disabled
            />
            <div class="mb-6">-</div>
            <text-input
              type="number"
              v-model.number="battery.battery_medium_value"
              v-on:input="handleBatteryInputChange"
              :disabled="!isBatteryEdit"
              class="pl-2"
            />
          </div>
        </section>
        <section>
          <input-label
            :text="$t('components.settingsManagement.vehicle.battery.high')"
          />
          <div class="flex items-center">
            <text-input
              :value="battery.battery_medium_value + 1"
              class="pr-2"
              disabled
            />
            <div class="mb-6">-</div>
            <text-input value="100" class="pl-2" disabled />
          </div>
        </section>
      </div>
      <!-- end: battery -->

      <!-- start:led control -->
      <div>
        <div>
          <div
            class="flex items-center mt-10 font-bold text-22px text-oCharcoal"
          >
            <i class="fas fa-lightbulb text-22px text-red-600"></i>
            <div class="pl-3 font-bold text-22px text-oCharcoal">
              <span>{{
                $t(
                  'components.settingsManagement.vehicle.headline.ledRingLightSettings'
                )
              }}</span>
            </div>
            <img class="ml-3 w-7 h-7" src="@/assets/img/new.png" />
          </div>
        </div>
      </div>
      <div
        :key="`ledRingLightControl_${counter}`"
        class=" mt-3 pt-5 border-t-2"
      >
        <SmartAppInput
          :key="`ledRingLightControl_${counter + 0}`"
          :rowId="`ledRingLightControl_${0}`"
          :type="ledControlSettings[0].type"
          :label="ledControlSettings[0].label"
          :description="ledControlSettings[0].description"
          :isEdit="ledControlSettings[0].isEdit"
          :options="ledControlTypeChoices"
          v-model="ledRingLightControl[`${ledControlSettings[0].variable}`]"
          @save="onOff('vehicle', `${ledControlSettings[0].variable}`, $event)"
        />
        <template
          v-if="ledRingLightControl.led_color_control_type === 'BATTERY_STATUS'"
        >
          <SmartAppInput
            :key="`ledRingLightControl_${counter + 1}`"
            :rowId="`ledRingLightControl_${1}`"
            :type="ledControlSettings[1].type"
            :label="ledControlSettings[1].label"
            :isEdit="ledControlSettings[1].isEdit"
            :options="ledControlColorChoices"
            v-model="ledRingLightControl[`${ledControlSettings[1].variable}`]"
            @save="
              onOff('vehicle', `${ledControlSettings[1].variable}`, $event)
            "
          />
          <SmartAppInput
            :key="`ledRingLightControl_${counter + 2}`"
            :rowId="`ledRingLightControl_${2}`"
            :type="ledControlSettings[2].type"
            :label="ledControlSettings[2].label"
            :isEdit="ledControlSettings[2].isEdit"
            :options="ledControlColorChoices"
            v-model="ledRingLightControl[`${ledControlSettings[2].variable}`]"
            @save="
              onOff('vehicle', `${ledControlSettings[2].variable}`, $event)
            "
          />
          <SmartAppInput
            :key="`ledRingLightControl_${counter + 3}`"
            :rowId="`ledRingLightControl_${3}`"
            :type="ledControlSettings[3].type"
            :label="ledControlSettings[3].label"
            :isEdit="ledControlSettings[3].isEdit"
            :options="ledControlColorChoices"
            v-model="ledRingLightControl[`${ledControlSettings[3].variable}`]"
            @save="
              onOff('vehicle', `${ledControlSettings[3].variable}`, $event)
            "
          />
          <SmartAppInput
            :key="`ledRingLightControl_${counter + 4}`"
            :rowId="`ledRingLightControl_${4}`"
            :type="ledControlSettings[4].type"
            :label="ledControlSettings[4].label"
            :isEdit="ledControlSettings[4].isEdit"
            :options="ledControlColorChoices"
            v-model="ledRingLightControl[`${ledControlSettings[4].variable}`]"
            @save="
              onOff('vehicle', `${ledControlSettings[4].variable}`, $event)
            "
          />
        </template>

        <template
          v-if="ledRingLightControl.led_color_control_type === 'TRIP_STATUS'"
        >
          <SmartAppInput
            :key="`ledRingLightControl_${counter + 5}`"
            :rowId="`ledRingLightControl_${5}`"
            :type="ledControlSettings[5].type"
            :label="ledControlSettings[5].label"
            :isEdit="ledControlSettings[5].isEdit"
            :options="ledControlColorChoices"
            v-model="ledRingLightControl[`${ledControlSettings[5].variable}`]"
            @save="
              onOff('vehicle', `${ledControlSettings[5].variable}`, $event)
            "
          />
          <SmartAppInput
            :key="`ledRingLightControl_${counter + 6}`"
            :rowId="`ledRingLightControl_${6}`"
            :type="ledControlSettings[6].type"
            :label="ledControlSettings[6].label"
            :isEdit="ledControlSettings[6].isEdit"
            :options="ledControlColorChoices"
            v-model="ledRingLightControl[`${ledControlSettings[6].variable}`]"
            @save="
              onOff('vehicle', `${ledControlSettings[6].variable}`, $event)
            "
          />
          <SmartAppInput
            :key="`ledRingLightControl_${counter + 7}`"
            :rowId="`ledRingLightControl_${7}`"
            :type="ledControlSettings[7].type"
            :label="ledControlSettings[7].label"
            :isEdit="ledControlSettings[7].isEdit"
            :options="ledControlColorChoices"
            v-model="ledRingLightControl[`${ledControlSettings[7].variable}`]"
            @save="
              onOff('vehicle', `${ledControlSettings[7].variable}`, $event)
            "
          />
        </template>
        <template
          v-if="
            ledRingLightControl.led_color_control_type === 'SCHEDULED_ACTIVITY'
          "
        >
          <SmartAppInput
            :key="`ledRingLightControl_${counter + 8}`"
            :rowId="`ledRingLightControl_${8}`"
            :type="ledControlSettings[8].type"
            :label="ledControlSettings[8].label"
            :isEdit="ledControlSettings[8].isEdit"
            :options="ledControlColorChoices"
            v-model="ledRingLightControl[`${ledControlSettings[8].variable}`]"
            @save="
              onOff('vehicle', `${ledControlSettings[8].variable}`, $event)
            "
          />
          <div class="md:mb-7">
            <div class="hidden py-2  md:flex ">
              <div class="w-1/3 font-bold text-14px text-oGray">
                Working Day
              </div>
              <div class="w-1/3 ml-2 font-bold text-14px text-oGray">
                On Time
              </div>
              <div class="w-1/3 -ml-4 font-bold text-14px text-oGray">
                Off Time
              </div>
            </div>

            <s-grid
              one="Working Day"
              two="Starting Time"
              three="Ending Time"
              variant="gray"
            >
              <template v-slot:one>
                <div class="mt-3">
                  <p class="font-bold text-14px text-oGray ">
                    Everyday
                  </p>
                </div>
              </template>
              <template v-slot:two>
                <div class="w-4/5">
                  <ValidationObserver class="flex items-center w-full">
                    <form
                      @submit.prevent
                      enctype="multipart/form-data"
                      class="w-full"
                    >
                      <ValidationProvider
                        vid="everyday"
                        rules=""
                        v-slot="{ errors }"
                      >
                        <template v-if="getInputLockState(`led_on_time`)">
                          <text-input
                            v-model="ledRingLightControl.led_on_time"
                            :disabled="true"
                          />
                        </template>
                        <template v-else>
                          <clock-picker
                            v-model="ledRingLightControl.led_on_time"
                            placeholder="--:--"
                          />
                          <input-error-item :message="errors[0]" />
                        </template>
                      </ValidationProvider>
                    </form>
                    <div>
                      <anchor-button
                        @click="
                          toggleEditingState(`led_on_time`, null, {
                            id: 'everyday',
                            led_on_time: ledRingLightControl.led_on_time,
                            led_off_time: ledRingLightControl.led_off_time,
                          })
                        "
                        :text="getButtonText(`led_on_time`)"
                        :variant="getButtonVariant(`led_on_time`)"
                        class="ml-3"
                      />
                      <input-error-item />
                    </div>
                  </ValidationObserver>
                </div>
              </template>
              <template v-slot:three>
                <div class="w-4/5">
                  <ValidationObserver class="flex items-center w-full">
                    <form
                      @submit.prevent
                      enctype="multipart/form-data"
                      class="w-full"
                    >
                      <ValidationProvider
                        vid="friday"
                        rules=""
                        v-slot="{ errors }"
                      >
                        <template v-if="getInputLockState(`led_off_time`)">
                          <text-input
                            v-model="ledRingLightControl.led_off_time"
                            :disabled="true"
                          />
                        </template>
                        <template v-else>
                          <clock-picker
                            v-model="ledRingLightControl.led_off_time"
                            placeholder="--:--"
                          />
                          <input-error-item :message="errors[0]" />
                        </template>
                      </ValidationProvider>
                    </form>
                    <div>
                      <anchor-button
                        @click="
                          toggleEditingState(`led_off_time`, null, {
                            id: 'everyday',
                            led_on_time: ledRingLightControl.led_on_time,
                            led_off_time: ledRingLightControl.led_off_time,
                          })
                        "
                        :text="getButtonText(`led_off_time`)"
                        :variant="getButtonVariant(`led_off_time`)"
                        class="ml-3"
                      />
                      <input-error-item />
                    </div>
                  </ValidationObserver>
                </div>
              </template>
            </s-grid>
          </div>
        </template>
      </div>
      <!-- end:led control -->
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import {
  BasicSettingConfig,
  VehicleSettingsConfig,
} from '@/config/SettingsConfig'
import inPlaceEditingMixin from '@/mixins/inPlaceEditingMixin'
import AnchorButton from '@/components/form/AnchorButton'
import InputLabel from '@/components/form/InputLabel'
import TextInput from '@/components/form/TextInput'
import SmartAppInput from '@/components/form/SmartAppInput.vue'
import SmartToggleInput from '@/components/form/SmartToggleInput.vue'
import SGrid from '@/components/layout/SGrid'
import ClockPicker from '@/components/picker/clock/components/ClockPicker'
import { EventBus } from '@/utils/EventBus'
import { useEndpoints } from '@/composables'
export default {
  name: 'VehicleSettings',
  mixins: [inPlaceEditingMixin],
  components: {
    InputLabel,
    TextInput,
    SmartAppInput,
    SmartToggleInput,
    AnchorButton,
    SGrid,
    ClockPicker,
  },
  data() {
    return {
      // todo: add isLoaded for preventing pre-rendering until data is loaded
      isLoaded: false,
      counter: 0,
      settings: {},
      isBatteryEdit: false,
      ledControlTypeChoices: [],
      ledControlColorChoices: [],
      vehicle: {
        vehicle_type: [],
        torch_on: false,
        torch_start_time: false,
        torch_end_time: false,
        auto_lock_vehicle_mins: null,
        heartbeat_idle_mins: null,
        battery_critical_value: null,
        battery_low_value: null,
        battery_medium_value: null,
        negative_balance_throttle_off: false,
        force_complete_trip_for_low_battery: false,
        low_battery_level: 0,
        should_lock_suspiciously_unlocked_vehicles: false,
        should_lock_wheel_while_end_ride: false,
      },
      ledRingLightControl: {
        led_color_control_type: 'NONE',

        led_color_critical_battery: '',
        led_color_low_battery: '',
        led_color_medium_battery: '',
        led_color_high_battery: '',

        led_color_on_trip: '',
        led_color_not_on_trip: '',
        led_color_reserved: '',
        led_color_for_scheduled_activity: '',
        led_on_time: '',
        led_off_time: '',
      },

      basic: {
        idle_vehicle_loc_update_mins: 10,
        low_battery_noti_percentage: 10,
      },

      battery: {
        battery_critical_value: 25,
        battery_low_value: 39,
        battery_medium_value: 59,
      },
      previousBatteryData: {
        battery_critical_value: null,
        battery_low_value: null,
        battery_medium_value: null,
      },

      vehicleSettings: [
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.vehicle.vehicle.suspiciousUnlockedVehicles.title'
          ),
          description: this.$t(
            'components.settingsManagement.vehicle.vehicle.suspiciousUnlockedVehicles.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'should_lock_suspiciously_unlocked_vehicles',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.vehicle.vehicle.autoLockVehicle.title'
          ),
          description: this.$t(
            'components.settingsManagement.vehicle.vehicle.autoLockVehicle.description'
          ),
          options: [
            { value: 5, text: '5' },
            { value: 6, text: '6' },
            { value: 7, text: '7' },
            { value: 8, text: '8' },
            { value: 9, text: '9' },
            { value: 10, text: '10' },
          ],
          variable: 'auto_lock_vehicle_mins',
          haveUnitText: true,
          unitText: 'Mins',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.vehicle.vehicle.autoLockTime.title'
          ),
          description: this.$t(
            'components.settingsManagement.vehicle.vehicle.autoLockTime.description'
          ),
          options: [
            { value: 5, text: '5' },
            { value: 6, text: '6' },
            { value: 7, text: '7' },
            { value: 8, text: '8' },
            { value: 9, text: '9' },
            { value: 10, text: '10' },
          ],
          variable: 'heartbeat_idle_mins',
          haveUnitText: true,
          unitText: 'Mins',
          isEdit: false,
        },

        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.vehicle.vehicle.headlight.title'
          ),
          description: this.$t(
            'components.settingsManagement.vehicle.vehicle.headlight.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.on'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.off'),
          variable: 'torch_on',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.vehicle.vehicle.headlightEnabledStartingHour.title'
          ),
          description: this.$t(
            'components.settingsManagement.vehicle.vehicle.headlightEnabledStartingHour.description'
          ),
          options: [
            { value: '00:00:00', text: '00:00 am' },
            { value: '01:00:00', text: '01:00 am' },
            { value: '02:00:00', text: '02:00 am' },
            { value: '03:00:00', text: '03:00 am' },
            { value: '04:00:00', text: '04:00 am' },
            { value: '05:00:00', text: '05:00 am' },
            { value: '06:00:00', text: '06:00 am' },
            { value: '07:00:00', text: '07:00 am' },
            { value: '08:00:00', text: '08:00 am' },
            { value: '09:00:00', text: '09:00 am' },
            { value: '10:00:00', text: '10:00 am' },
            { value: '11:00:00', text: '11:00 am' },
            { value: '12:00:00', text: '12:00 pm' },
            { value: '13:00:00', text: '13:00 pm' },
            { value: '14:00:00', text: '14:00 pm' },
            { value: '15:00:00', text: '15:00 pm' },
            { value: '16:00:00', text: '16:00 pm' },
            { value: '17:00:00', text: '17:00 pm' },
            { value: '18:00:00', text: '18:00 pm' },
            { value: '19:00:00', text: '19:00 pm' },
            { value: '20:00:00', text: '20:00 pm' },
            { value: '21:00:00', text: '21:00 pm' },
            { value: '22:00:00', text: '22:00 pm' },
            { value: '23:00:00', text: '23:00 pm' },
            { value: '24:00:00', text: '24:00 pm' },
          ],
          variable: 'torch_start_time',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.vehicle.vehicle.headlightEnabledEndingHour.title'
          ),
          description: this.$t(
            'components.settingsManagement.vehicle.vehicle.headlightEnabledEndingHour.description'
          ),
          options: [
            { value: '00:00:00', text: '00:00 am' },
            { value: '01:00:00', text: '01:00 am' },
            { value: '02:00:00', text: '02:00 am' },
            { value: '03:00:00', text: '03:00 am' },
            { value: '04:00:00', text: '04:00 am' },
            { value: '05:00:00', text: '05:00 am' },
            { value: '06:00:00', text: '06:00 am' },
            { value: '07:00:00', text: '07:00 am' },
            { value: '08:00:00', text: '08:00 am' },
            { value: '09:00:00', text: '09:00 am' },
            { value: '10:00:00', text: '10:00 am' },
            { value: '11:00:00', text: '11:00 am' },
            { value: '12:00:00', text: '12:00 pm' },
            { value: '13:00:00', text: '13:00 pm' },
            { value: '14:00:00', text: '14:00 pm' },
            { value: '15:00:00', text: '15:00 pm' },
            { value: '16:00:00', text: '16:00 pm' },
            { value: '17:00:00', text: '17:00 pm' },
            { value: '18:00:00', text: '18:00 pm' },
            { value: '19:00:00', text: '19:00 pm' },
            { value: '20:00:00', text: '20:00 pm' },
            { value: '21:00:00', text: '21:00 pm' },
            { value: '22:00:00', text: '22:00 pm' },
            { value: '23:00:00', text: '23:00 pm' },
            { value: '24:00:00', text: '24:00 pm' },
          ],
          variable: 'torch_end_time',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.vehicle.vehicle.throttleControlOnNegativeBalance.title'
          ),
          description: this.$t(
            'components.settingsManagement.vehicle.vehicle.throttleControlOnNegativeBalance.description'
          ),
          onValue: true,
          onText: this.$t(
            'components.settingsManagement.commonAction.throttleOn'
          ),
          offValue: false,
          offText: this.$t(
            'components.settingsManagement.commonAction.throttleOff'
          ),
          variable: 'negative_balance_throttle_off',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.vehicle.vehicle.idleVehicleLocationUpdate.title'
          ),
          placeholder: `e.g 3 m`,
          description: this.$t(
            'components.settingsManagement.vehicle.vehicle.idleVehicleLocationUpdate.description'
          ),
          options: [
            { value: 1, text: '1' },
            { value: 2, text: '2' },
            { value: 3, text: '3' },
            { value: 4, text: '4' },
            { value: 5, text: '5' },
            { value: 6, text: '6' },
            { value: 7, text: '7' },
            { value: 8, text: '8' },
            { value: 9, text: '9' },
            { value: 10, text: '10' },
          ],
          variable: 'idle_vehicle_loc_update_mins',
          haveUnitText: true,
          unitText: 'Mins',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.vehicle.vehicle.lowBatteryNotification.title'
          ),
          placeholder: `e.g 10%`,
          description: this.$t(
            'components.settingsManagement.vehicle.vehicle.lowBatteryNotification.description'
          ),
          options: [
            { value: '05.00', text: '5' },
            { value: '10.00', text: '10' },
            { value: '15.00', text: '15' },
            { value: '20.00', text: '20' },
            { value: '25.00', text: '25' },
            { value: '30.00', text: '30' },
          ],
          variable: 'low_battery_noti_percentage',
          haveUnitText: true,
          unitText: '%',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.vehicle.vehicle.lockWheelWhileEndingRide.title'
          ),
          description: this.$t(
            'components.settingsManagement.vehicle.vehicle.lockWheelWhileEndingRide.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'should_lock_wheel_while_end_ride',
          isEdit: false,
        },
      ],
      ledControlSettings: [
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.vehicle.ledRingLight.ledColorControl.title'
          ),
          placeholder: ``,
          description: this.$t(
            'components.settingsManagement.vehicle.ledRingLight.ledColorControl.description'
          ),
          variable: 'led_color_control_type',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.vehicle.ledRingLight.ledColorForCriticalBattery.title'
          ),
          placeholder: ``,
          variable: 'led_color_critical_battery',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.vehicle.ledRingLight.ledColorForLowBattery.title'
          ),
          placeholder: ``,
          variable: 'led_color_low_battery',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.vehicle.ledRingLight.ledColorForMediumBattery.title'
          ),
          placeholder: ``,
          variable: 'led_color_medium_battery',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.vehicle.ledRingLight.ledColorForHighBattery.title'
          ),
          placeholder: ``,
          variable: 'led_color_high_battery',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.vehicle.ledRingLight.ledColorOntrip.title'
          ),
          placeholder: ``,
          variable: 'led_color_on_trip',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.vehicle.ledRingLight.ledColorForNotOnTrip.title'
          ),
          placeholder: ``,
          variable: 'led_color_not_on_trip',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.vehicle.ledRingLight.ledColorForReserved.title'
          ),
          placeholder: ``,
          variable: 'led_color_reserved',
          isEdit: false,
        },
        {
          type: 'color',
          label: this.$t(
            'components.settingsManagement.vehicle.ledRingLight.ledColorScheduled.title'
          ),
          placeholder: ``,
          description: ``,
          variable: 'led_color_for_scheduled_activity',
          isEdit: false,
        },
      ],
    }
  },
  computed: {
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
    getVehicleSettings() {
      return this.vehicleSettings
    },
  },

  async created() {
    const requests = [
      this.$http.get(VehicleSettingsConfig.api.index(this.orgId)),
      this.$http.get(BasicSettingConfig.api.index(this.orgId)),
      this.$http.get(useEndpoints.settings.vehicle.ledControlChoices()),
      this.$http.get(useEndpoints.settings.vehicle.ledColorChoices()),
    ]
    await this.$http.all(requests).then(
      this.$http.spread((...responses) => {
        this.vehicle = {
          ...responses[0].data,
          negative_balance_throttle_off: !responses[0].data
            .negative_balance_throttle_off,
        }

        this.ledRingLightControl = {
          led_color_control_type: responses[0].data.led_color_control_type,

          led_color_critical_battery:
            responses[0].data.led_color_critical_battery,
          led_color_low_battery: responses[0].data.led_color_low_battery,
          led_color_medium_battery: responses[0].data.led_color_medium_battery,
          led_color_high_battery: responses[0].data.led_color_high_battery,

          led_color_on_trip: responses[0].data.led_color_on_trip,
          led_color_not_on_trip: responses[0].data.led_color_not_on_trip,
          led_color_reserved: responses[0].data.led_color_reserved,
          led_color_for_scheduled_activity: `#${responses[0].data.led_color_for_scheduled_activity}`,
          led_on_time: responses[0].data.led_on_time
            ? this.formatTimeForClockPicker(responses[0].data.led_on_time)
            : '00:00',
          led_off_time: responses[0].data.led_off_time
            ? this.formatTimeForClockPicker(responses[0].data.led_off_time)
            : '23:59',
        }

        // battery
        this.$set(
          this.battery,
          'battery_critical_value',
          responses[0].data.battery_critical_value
        )
        this.$set(
          this.battery,
          'battery_low_value',
          responses[0].data.battery_low_value
        )
        this.$set(
          this.battery,
          'battery_medium_value',
          responses[0].data.battery_medium_value
        )

        // basic

        this.$set(
          this.basic,
          'idle_vehicle_loc_update_mins',
          responses[1].data.idle_vehicle_loc_update_mins
        )

        this.$set(
          this.basic,
          'low_battery_noti_percentage',
          responses[1].data.low_battery_noti_percentage
        )
        this.ledControlTypeChoices = responses[2].data
        this.ledControlColorChoices = responses[3].data
        this.isLoaded = true
      })
    )
    this.setLedScheduleState()
  },
  mounted() {
    EventBus.$on('open-edit', (id) => {
      const idPrefix = id.split('_')[0]
      if (idPrefix === 'vehicle') {
        this.vehicleSettings = this.vehicleSettings.map((item, itemIndex) => {
          if (id === `vehicle_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        if (this.isBatteryEdit) {
          this.isBatteryEdit = false
          this.handleBatteryCancel()
        }

        this.ledControlSettings = this.ledControlSettings.map((item) => ({
          ...item,
          isEdit: false,
        }))
      }
      if (idPrefix === 'ledRingLightControl') {
        this.ledControlSettings = this.ledControlSettings.map(
          (item, itemIndex) => {
            if (id === `ledRingLightControl_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )

        this.vehicleSettings = this.vehicleSettings.map((item) => ({
          ...item,
          isEdit: false,
        }))

        if (this.isBatteryEdit) {
          this.isBatteryEdit = false
          this.handleBatteryCancel()
        }
      }
    })
  },
  methods: {
    setLedScheduleState() {
      Vue.set(this.inPlaceEditingState, `led_on_time`, false)

      Vue.set(this.inPlaceEditingState, `led_off_time`, false)
    },
    formatTimeForClockPicker(time) {
      let parts = time.split(':')
      return parts[0] + ':' + parts[1]
    },
    async inPlaceEditingRequest(group, key, data) {
      console.log(group, key)
      console.log(data)
      const formData = new FormData()
      formData.append('led_on_time', data.led_on_time)
      formData.append('led_off_time', data.led_off_time)

      await this.$http
        .patch(VehicleSettingsConfig.api.update(this.orgId), formData)
        .then((res) => {
          console.log(res)
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Success',
              text: 'Time has been changed',
            },
            3000
          )
          return true
        })
        .catch((err) => {
          // todo: reverse toggle state
          console.log('inPlaceE', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Failed',
              text: 'Failed to update hour',
            },
            3000
          )
        })
    },
    handleBatteryEdit() {
      this.vehicleSettings = this.vehicleSettings.map((item) => ({
        ...item,
        isEdit: false,
      }))
      this.isBatteryEdit = true
      this.previousBatteryData.battery_critical_value = this.battery.battery_critical_value
      this.previousBatteryData.battery_low_value = this.battery.battery_low_value
      this.previousBatteryData.battery_medium_value = this.battery.battery_medium_value
    },
    handleBatteryCancel() {
      this.isBatteryEdit = false
      this.battery.battery_critical_value = this.previousBatteryData.battery_critical_value
      this.battery.battery_low_value = this.previousBatteryData.battery_low_value
      this.battery.battery_medium_value = this.previousBatteryData.battery_medium_value
    },
    handleBatteryInputChange() {
      if (
        this.battery.battery_low_value < this.battery.battery_critical_value
      ) {
        this.battery.battery_low_value = this.battery.battery_critical_value + 1
      }
      if (this.battery.battery_medium_value < this.battery.battery_low_value) {
        this.battery.battery_medium_value = this.battery.battery_low_value + 1
      }
    },

    async onOff(type, key, val = null) {
      let toastTitle, toastText

      if (key === 'torch_on') {
        let onOffText = val ? 'on' : 'off'
        toastTitle = `Torch ${onOffText}`
        toastText = `Always on headlight turned ${onOffText}`
      }

      if (key === 'torch_start_time') {
        toastTitle = `Torch Schedule Updated`
        toastText = `Torch enabled starting time updated`
      }

      if (key === 'torch_end_time') {
        toastTitle = `Torch Schedule Updated`
        toastText = `Torch enabled ending time updated`
      }

      if (key === 'auto_lock_vehicle_mins') {
        toastTitle = `Auto Lock Time (Active Heartbeat)`
        toastText = `Automatic locking of vehicle time updated`
      }

      if (key === 'heartbeat_idle_mins') {
        toastTitle = `Auto Lock Time (On No Heartbeat)`
        toastText = `Automatic locking of vehicle time updated`
      }

      if (key === 'battery_settings') {
        toastTitle = `Battery Settings`
        toastText = `Battery Settings is updated`
      }
      if (key === 'low_battery_level') {
        toastTitle = `Low Battery Charge`
        toastText = `Low Battery Charge has been updated`
      }
      if (key === 'negative_balance_throttle_off') {
        toastTitle = `Success`
        toastText = `Status has been updated`
      }
      if (key === 'idle_vehicle_loc_update_mins') {
        toastTitle = `Success`
        toastText = `Status has been updated`
      }
      if (key === 'low_battery_noti_percentage') {
        toastTitle = `Success`
        toastText = `Status has been updated`
      }

      if (key === 'should_lock_suspiciously_unlocked_vehicles') {
        toastTitle = `Success`
        toastText = `Status has been updated`
      }
      if (key === 'should_lock_wheel_while_end_ride') {
        toastTitle = `Success`
        toastText = `Status has been updated`
      }

      if (key === 'led_color_control_type') {
        toastTitle = `Success`
        toastText = `Status has been updated`
      }

      if (key === 'led_color_critical_battery') {
        toastTitle = `Success`
        toastText = `Status has been updated`
      }
      if (key === 'led_color_low_battery') {
        toastTitle = `Success`
        toastText = `Status has been updated`
      }
      if (key === 'led_color_medium_battery') {
        toastTitle = `Success`
        toastText = `Status has been updated`
      }
      if (key === 'led_color_high_battery') {
        toastTitle = `Success`
        toastText = `Status has been updated`
      }

      if (key === 'led_color_on_trip') {
        toastTitle = `Success`
        toastText = `Status has been updated`
      }
      if (key === 'led_color_not_on_trip') {
        toastTitle = `Success`
        toastText = `Status has been updated`
      }
      if (key === 'led_color_reserved') {
        toastTitle = `Success`
        toastText = `Status has been updated`
      }

      if (key === 'led_color_for_scheduled_activity') {
        toastTitle = `Success`
        toastText = `Color has been updated`
      }

      let url = ''
      let data = new FormData()
      if (type === 'vehicle') {
        url = VehicleSettingsConfig.api.update(this.orgId)

        if (key === 'battery_settings') {
          data.append(
            'battery_critical_value',
            this.battery.battery_critical_value
          )
          data.append('battery_low_value', this.battery.battery_low_value)
          data.append('battery_medium_value', this.battery.battery_medium_value)
        } else if (key === 'negative_balance_throttle_off') {
          data.append(key, !val)
        } else if (key === 'led_color_for_scheduled_activity') {
          data.append(key, val.replace('#', ''))
        } else {
          data.append(key, val)
        }
      }
      if (type === 'basic') {
        url = BasicSettingConfig.api.update(this.orgId)

        data.append(key, val)
      }

      console.log('link', data)
      await this.$http
        .patch(url, data)
        .then((res) => {
          console.log(res)
          this.vehicleSettings = this.vehicleSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.isBatteryEdit = false

          this.ledControlSettings = this.ledControlSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: toastTitle,
              text: toastText,
            },
            3000
          )
          this.counter++
        })
        .catch((err) => {
          // todo: reverse toggle state
          console.log('onOffE', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: toastTitle,
              text: 'Failed to update settings',
            },
            3000
          )
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-for-torch {
  background-color: #f9fff8;
}
.size-for-torch {
  height: 58px;
}
.pencil-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.description-text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 2px;
  margin-bottom: 1px;
}
</style>
